<template>
  <div class="mywallet">
    <Header></Header>
    <div class="breadcrumb-wrap bg-f br-1">
      <div class="overlay bg-black op-9"></div>
      <img :src="shape1" alt="Image" class="br-shape-1" />
      <img :src="shape2" alt="Image" class="br-shape-2" />
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="breadcrumb-title">
              <h2>My Wallet</h2>
              <ul class="breadcrumb-menu list-style">
                <li>
                  <!--<a href="index-2.html">Home </a>--><router-link
                    to="/"
                    style="color: white"
                    >Home</router-link
                  >
                </li>
                <li>My Wallet</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blog-details-wrap ptb-100">
      <div class="container">
        <div class="row">
          <div
            class="col-xl-4 col-lg-12 order-xl-1 order-lg-2 order-md-2 order-2"
          >
            <div class="sidebar">
              <Leftsidebar></Leftsidebar>
            </div>
          </div>
          <div
            class="col-xl-8 col-lg-12 order-xl-2 order-lg-1 order-md-1 order-1"
          >
            <h4 class="border-bottom pb-4">
              <i class="fa fa-money" aria-hidden="true"></i>
              My Wallet
            </h4>
            <div class="container d-flex justify-content-center mt-5">
              <div class="card color text-white">
                <div class="text-right image-background mt-4 mb-2">
                  <div class="px-2">
                    <img :src="visacardchip" width="40" />
                  </div>
                </div>
                <div class="px-2 mt-2">
                  <h5 class="text-white">Balance Amount</h5>
                </div>
                <div class="d-flex flex-row align-items-center px-2">
                  <div class="dots mr-1">
                    <span></span> <span></span> <span></span> <span></span>
                  </div>
                  <span class="number"
                    >${{
                      wallet.balance > 0 ? wallet.balance.toFixed("2") : 0
                    }}/-</span
                  >
                </div>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    mt-2
                    px-2
                    mb-2
                  "
                >
                  <span></span>
                  <img :src="visa" width="40" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Leftsidebar from "@/views/sidebar/Leftsidebar.vue";
import Footer from "@/views/layouts/Footer.vue";
import { getUserWallet } from "@/store/api";
export default {
  props: {},
  name: "mywallet",
  data() {
    return {
      shape1: require("@/assets/img/shape-1.png"),
      shape2: require("@/assets/img/shape-2.png"),
      visacardchip: require("@/assets/img/visa-card-chip.png"),
      visa: require("@/assets/img/visa.png"),
      wallet: "",
    };
  },
  components: {
    Header,
    Leftsidebar,
    Footer,
  },
  mounted() {
    this.getWallet();
  },
  methods: {
    getWallet() {
      const data = "";
      getUserWallet(data).then((res) => {
        this.wallet = res.data;
        console.log(res.data);
      });
    },
  },
};
</script>
<style>
.card.color {
  width: 390px;
  height: 220px;
  background-image: linear-gradient(
    to right top,
    #280537,
    #56034c,
    #890058,
    #bc005b,
    #eb1254
  );
  border-radius: 15px;
  border: none;
}

.image-background {
  background: #ea92a1;
}

.dots span {
  height: 5px;
  width: 5px;
  margin-right: 3px;
  margin-bottom: 2px !important;
  background-color: rgb(255, 255, 255);
  border-radius: 50%;
  display: inline-block;
}

.number {
  font-size: 24px;
}
</style>